<template>
  <div>
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div class="relative text-gray-600">
          <input
            v-model="searchString"
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-gray-100
              h-7
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
            @keydown="handleSearchKeyPress"
            @keyup="handleSearchKeyUp"
          />
          <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
      <ul class="flex items-center">
        <!-- <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            text-sm
            py-1
            rounded-lg
            px-3
            hover:bg-black
            mr-2
            flex
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 fill-current mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M17 9.5H3M21 4.5H3M21 14.5H3M17 19.5H3" />
          </svg>

          Sort
        </li> -->

        <!-- <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            text-sm
            py-1
            rounded-lg
            px-3
            hover:bg-black
            mr-2
            flex
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 fill-current mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="4" y1="21" x2="4" y2="14"></line>
            <line x1="4" y1="10" x2="4" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="3"></line>
            <line x1="20" y1="21" x2="20" y2="16"></line>
            <line x1="20" y1="12" x2="20" y2="3"></line>
            <line x1="1" y1="14" x2="7" y2="14"></line>
            <line x1="9" y1="8" x2="15" y2="8"></line>
            <line x1="17" y1="16" x2="23" y2="16"></line>
          </svg>
          Filter
        </li> -->

        <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
          @click="newVendorModalVisible = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New Vendor
        </li>
      </ul>
    </div>
  </div>
  <!-- <NewInquiryModal
    @overlayClicked="newInquiryOverlayClicked"
    :isVisible="newInquiryVisible"
  ></NewInquiryModal> -->

  <NewVendorModal
    @vendorAdded="addVendorContact"
    @overlayClicked="newVendorModalVisible = false"
    :isVisible="newVendorModalVisible"
  />

  <div class="inq-table">
    <div>
      <div class="flex flex-col">
        <div class="flex-grow relative">
          <SearchLoadingOverlay v-if="loading"></SearchLoadingOverlay>
          <table class="relative w-full border">
            <thead class="w-full border border-t-o border-r-0 border-l-0">
              <tr class="text-left">
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                    pl-2
                  "
                >
                  <input
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-gray-600"
                  />
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  COMPANY
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  DEPT
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  NAME
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  EMAIL
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  PHONE
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody class="divide-y text-xs">
              <!-- ADD INQUIRY ITEM -->
              <!-- <InquiryTableItem
                :inquiry="inquiry"
                v-for="inquiry in inquiries"
                :key="inquiry._id"
              ></InquiryTableItem> -->
              <VendorTableItem
                v-for="vendor in vendors"
                :key="vendor._id"
                :vendor="vendor"
              />
            </tbody>
          </table>
          <div
            :class="{ hidden: paginationLinks.length <= 1 }"
            class="text-sm flex items-center justify-center pagination my-5"
          >
            <button
              @click="
                $store.commit(mutationTypes.SET_VENDORS_PAGE, page - 1),
                  getVendorContacts()
              "
              class="px-2 py-1"
              v-if="page - 1 > 0"
            >
              Previous
            </button>
            <button
              :disabled="buttonPage == '...'"
              :class="{
                shadow: buttonPage == page,
                'cursor-default': buttonPage == '...',
              }"
              class="px-2 py-1"
              v-for="(buttonPage, i) in paginationLinks"
              @click.prevent="
                $store.commit(mutationTypes.SET_VENDORS_PAGE, buttonPage),
                  getVendorContacts()
              "
              :key="i"
            >
              {{ buttonPage }}
            </button>
            <button
              @click="
                $store.commit(mutationTypes.SET_VENDORS_PAGE, page + 1),
                  getVendorContacts()
              "
              v-if="page + 1 <= totalPages"
              class="px-2 py-1"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewVendorModal from "@/components/modules/vendor-contacts/NewVendorModal";
import VendorTableItem from "@/components/modules/vendor-contacts/VendorTableItem";
import mutationTypes from "@/utils/mutation-types";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "Vendors",
  data() {
    return {
      mutationTypes,
      newVendorModalVisible: false,
      searchTypingTimeout: 500,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["addVendorContact", "getVendorContacts"]),
    async searchItems() {
      await this.getVendorContacts();

      this.loading = false;
    },
    handleSearchKeyPress() {
      window.clearTimeout(window.searchTimer);
      this.loading = true;
    },
    handleSearchKeyUp() {
      window.clearTimeout(window.searchTimer);

      window.searchTimer = window.setTimeout(() => {
        this.searchItems();
        // console.log("test");
      }, this.searchTypingTimeout);
      // console.log(this.timer);
    },
  },
  computed: {
    ...mapGetters({
      vendors: "vendorContacts",
      totalPages: "vendorsTotalPages",
      page: "vendorsPage",
    }),

    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },

    searchString: {
      get() {
        return this.$store.getters.vendorsSearchValue;
      },
      set(val) {
        this.$store.commit(mutationTypes.SET_VENDORS_SEARCH, val);
      },
    },

    paginationLinks() {
      const { page, totalPages } = this.$store.state.vendors;

      const pageNumArr = [];

      const arr = [];

      if (totalPages > 6) {
        for (let i = 1; i <= totalPages; i++) pageNumArr.push(i);
        const currentPageIndex = pageNumArr.indexOf(page);
        let sliceStartIndex = 0;
        if (currentPageIndex > 0) sliceStartIndex = currentPageIndex - 1;

        if (pageNumArr.slice(sliceStartIndex).length > 6) {
          arr.push(...pageNumArr.slice(sliceStartIndex, sliceStartIndex + 3));
          arr.push("...");
          arr.push(...pageNumArr.slice(-3));
        } else {
          arr.push(...pageNumArr.slice(pageNumArr.length - 6));
        }
      } else {
        for (let i = 1; i <= totalPages; i++) arr.push(i);
      }
      //   console.log(arr);
      return arr;
    },

    topBarLeftMargin() {
      this.sidebarCollapsed;

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },

    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  mounted() {
    this.getVendorContacts();
  },
  components: {
    VendorTableItem,
    NewVendorModal,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}

.sidebar {
  width: 16.5%;
}
.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
  th {
    position: sticky;
    z-index: 48;
    top: 5.75rem;
  }
}
</style>